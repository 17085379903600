import Layout from "../../components/layout"
import React from "react"
import SEO from "../../components/seo"

function LoweOferta() {
  return (
    <Layout>
      <SEO title="LOWE Oferta - ZSP Nidek" />
      <h1>LOWE Oferta</h1>
      <ul>
        <li>
          <a
            href="../../../documents/lowe/oferta/LOWE_Wieprz_Oferta_edukacyjna_ogrod_w_szkle.docx"
            download="LOWE_Wieprz_Oferta_edukacyjna_ogrod_w_szkle.docx"
          >
            Ogród w szkle - sukulenty (56KB | docx)
          </a>
        </li>

        <li>
          <a
            href="../../../documents/lowe/oferta/LOWE_Wieprz_Oferta_edukacyjna_dietetyk.docx"
            download="LOWE_Wieprz_Oferta_edukacyjna_dietetyk.docx"
          >
            Dobrze dobrana dieta w dążeniu do poprawy zdrowia (56KB | docx)
          </a>
        </li>

        <li>
          <a
            href="../../../documents/lowe/oferta/LOWE_Wieprz_Oferta_edukacyjna_pisanki.docx"
            download="LOWE_Wieprz_Oferta_edukacyjna_pisanki.docx"
          >
            Pisanki, kraszanki … woskiem malowane (56KB | docx)
          </a>
        </li>

        <li>
          <a
            href="../../../documents/lowe/oferta/LOWE_Wieprz_Oferta_edukacyjna_pileczka_w_stres.docx"
            download="LOWE_Wieprz_Oferta_edukacyjna_pileczka_w_stres.docx"
          >
            Piłeczką w stres (57KB | docx)
          </a>
        </li>

        <li>
          <a
            href="../../../documents/lowe/oferta/LOWE_Wieprz_Oferta_edukacyjna_nordic_walking.docx"
            download="LOWE_Wieprz_Oferta_edukacyjna_nordic_walking.docx"
          >
            Z kijkami po zdrowie (58KB | docx)
          </a>
        </li>

        <li>
          <a
            href="../../../documents/lowe/oferta/LOWE_Wieprz_Oferta_edukacyjna_pozar.docx"
            download="LOWE_Wieprz_Oferta_edukacyjna_pozar.docx"
          >
            Pożar wewnętrzny – przyczyny, zagrożenia, sposób postępowania. (86KB
            | docx)
          </a>
        </li>

        <li>
          <a
            href="../../../documents/lowe/oferta/LOWE_Wieprz_Oferta_edukacyjna_platformy_komunik.docx"
            download="LOWE_Wieprz_Oferta_edukacyjna_platformy_komunik.docx"
          >
            Wirtualna konwersacja (57KB | docx)
          </a>
        </li>

        <li>
          <a
            href="../../../documents/lowe/oferta/LOWE_Wieprz_Oferta_edukacyjna_finanse_transakcje.docx"
            download="LOWE_Wieprz_Oferta_edukacyjna_finanse_transakcje.docx"
          >
            Moje finanse i transakcje w sieci. (85KB | docx)
          </a>
        </li>

        <li>
          <a
            href="../../../documents/lowe/oferta/LOWE_Wieprz_Oferta_edukacyjna_pilotaz_drona.docx"
            download="LOWE_Wieprz_Oferta_edukacyjna_pilotaz_drona.docx"
          >
            Warsztaty pilotażu drona. (88KB | docx)
          </a>
        </li>

        <li>
          <a
            href="../../../documents/lowe/oferta/LOWE_Wieprz_Oferta_edukacyjna_rodzina.docx"
            download="LOWE_Wieprz_Oferta_edukacyjna_rodzina.docx"
          >
            Program Wzmacniania Rodzin 10-14. (72KB | docx)
          </a>
        </li>

        <li>
          <a
            href="../../../documents/lowe/oferta/LOWE_Wieprz_Oferta_edukacyjna_szkola_rodzicow.docx"
            download="LOWE_Wieprz_Oferta_edukacyjna_szkola_rodzicow.docx"
          >
            Szkoła dla Rodziców i Wychowawców (69KB | docx)
          </a>
        </li>
      </ul>
    </Layout>
  )
}

export default LoweOferta
